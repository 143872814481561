
import Vue from 'vue';
import MicrosoftChangePeriodResultTemplate from '@/templates/ms365/MicrosoftChangePeriodResultTemplate.vue';

export default Vue.extend({
  components: {
    MicrosoftChangePeriodResultTemplate,
  },
  data() {
    return {
      // [상품 상세 정보]
      goodsDetailInfo: {
        service_name: 'Microsoft365',
        domain: '', // 도메인
        goods_type: '', // 상품 타입
        apply_content: '이용 기간 변경 (1개월 → 1년)',
      },
      oldServiceSeq: 0, // service_id
    };
  },
  computed: {
    userId(): string {
      return this.$store.state.userInfo?.user_id ?? undefined;
    },
  },
  async mounted() {
    try {
      const { goods, hosting, client } = this.$store.state.changePeriod.msoffice365;

      if (this.userId !== client.user_id) {
        throw new Error('로그인 유저와 조회 유저가 동일하지 않습니다.');
      }

      if (goods.term_unit_code.code !== 'month') {
        throw new Error('해당 상품은 1개월 상품이 아닙니다.');
      }

      this.goodsDetailInfo = {
        ...this.goodsDetailInfo,
        domain: hosting.domain, // 도메인
        goods_type: goods.expose_name, // 상품 타입
      };
    } catch (err) {
      // 오류 발생시 (상세 오류 메세지는 표시하지 않음)
      alert('오류가 발생하였습니다.');
      window.location.href = process.env.VUE_APP_GABIA_URL;
    } finally {
      this.$GlobalLoading.hide();
    }
  },
});
